export function edit_result() {
    $("button.edit-result").each(function () {
        let $this  = $(this);
        let $url = $this.data('url');
        let $game  = $this.data('game');
        let $modal = $('#modalResult');
        let _loading = '<div class="text-center p-5 mt-5"><div class="spinner-grow text-dark" style="width: 5rem; height: 5rem;" role="status"></div></div>';
        let utils = {
            start:function () {
                $modal.find('.modal-dialog').html(_loading);
            },
            serialezerForm:function () {
                let $values = {};
                $modal.find('.modal-body form').find('input').each(function (i,j) {
                    let $element = $(j);
                    $values[$element.attr('name')]=$element.val();
                });
                return $values;
            },
            bindClick:function () {
                $modal.find('.modal-footer button.save').on('click',submitForm);
            },
            updateResult:function (resultTeam,resultVisitingTeam) {
                $('span#game'+$game).html(resultTeam+' - '+resultVisitingTeam);
            }
        };

        function getForm(){
            $.ajax($url,{
               success:function (data) {
                   $modal.find('.modal-dialog').html(data.view);
                   utils.bindClick();
                   
               },
               error:function () {

               }
            });
        }

        function submitForm(){
            let $values = utils.serialezerForm();
            utils.start();
            $.post($url,$values,function(data){
                $modal.find('.modal-dialog').html(data.view);
                utils.bindClick();
                if(data.valid)
                    utils.updateResult(data.resultTeam,data.resultVisitingTeam)
            });
        }

        $this.on('click',function (e) {
            e.preventDefault();
            utils.start();
            $modal.modal('show');
            getForm();
        });
    })
}