export function document() {
    $(".document_upload").each(function () {
        let $this = $(this);
        let $button = $this.find('button');
        let $file = $this.find('input[type=file]');
        let $progress = $this.find('.progress');
        let $textFalse = $this.find('.text-false');
        let $value = $this.find('input[type=text]');
        let $prototype = '<div class="file"><span>__name</span><span class="size text-muted">__size</span><i class="fa fa-check-circle check"></i><i class="fa fa-times-circle close"></i></div>';
        let $dragDrop = $this.find('.drag-drop');
        let $containerDocument = $this.find('.container-document');
        let $check = null;
        let $close = null;

        let $progress_load = {
            start:function () {
                $progress.attr('hidden',null);
                $textFalse.attr('hidden','hidden');
                $containerDocument.find('.file').remove();
                $value.val(null);
            },
            stop:function () {
                $progress.find('.progress-bar').css('width','0%');
                $progress.attr('hidden','hidden');
                $textFalse.attr('hidden',null);
                $file.val(null);
            },
            stopDone:function () {
                $textFalse.attr('hidden','hidden');

            },
            startDragDrop:function () {
                $dragDrop.attr('hidden',null);
            },
            stopDragDrop:function () {
                $dragDrop.attr('hidden','hidden');
            }

        };

        let overCheck = function(){
            $close.on({
                mouseenter: function () {
                    $check.css('opacity','0');
                },
                mouseleave: function () {
                    $check.css('opacity','1');
                }
            });
        };

        let updateCheckOver = function(){
            $check = $this.find('.check');
            $close = $this.find('.close');
        };

        let clickClose = function(){
            $this.find('.file').remove();
            $progress_load.stop();
            $value.val(null);
        };

        $button.on('click',function (e) {
            e.preventDefault();
            $file.trigger('click');
        });


        $file.fileupload({
            url: $this.data('url'),
            replaceFileInput: false,
            formData:{'path':$this.data('path')},
            dropZone: $this,
            acceptFileTypes: $this.data('accept'),
            done: function (e, parameters) {
                $progress_load.stop();
                if(parameters.result.status == 'OK') {
                    $progress_load.stopDone();
                    $value.val(parameters.result.filename);
                    $containerDocument.append($prototype.replace(/__name/g, parameters.result.original).replace(/__size/g, parameters.result.size));
                    updateCheckOver();
                    overCheck();
                    $close.on('click', clickClose);
                }
                if(parameters.result.status == 'KO'){

                    new Noty({
                        type: 'error',
                        layout: 'topRight',
                        text: parameters.result.message,
                        theme: 'metroui',
                        timeout: 5000
                    }).show();
                }
            },
            error: function () {
                $progress_load.stop();
                new Noty({
                    type: 'error',
                    layout: 'topRight',
                    text: parameters.result.message,
                    theme: 'metroui',
                    timeout: 5000
                }).show();
            },
            start:function(){
                $progress_load.stopDragDrop();
                $progress_load.start();
            },
            progressall: function (e, data) {
                let progress = parseInt(data.loaded / data.total * 100, 10);
                $progress.find('.progress-bar').css('width',progress+'%');
            },
            dragover:function () {
                $progress_load.startDragDrop();
                $dragDrop.addClass('over');
            },
            drop:function () {
                $progress_load.stopDragDrop();
            },
            dragleave: function () {
                $dragDrop.removeClass('over');
            },
        });

        updateCheckOver();
        overCheck();
        $close.on('click',clickClose);


    })
}