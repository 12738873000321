export function app_flashes() {
    $(document.flashes_success).each(function (v) {
        new Noty({
            type: 'success',
            layout: 'topRight',
            text: document.flashes_success[v],
            theme: 'metroui',
            timeout: 5000,
        }).show();
    });

    $(document.flashes_error).each(function (v) {
        new Noty({
            type: 'error',
            layout: 'topRight',
            text: document.flashes_error[v],
            theme: 'metroui',
            timeout: 5000
        }).show();
    });

    $(document.flashes_warning).each(function (v) {
        new Noty({
            type: 'warning',
            layout: 'topRight',
            text: document.flashes_warning[v],
            theme: 'metroui',
            timeout: 5000
        }).show();
    });
};