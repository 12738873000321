'use strict';
import $ from 'jquery';
import 'bootstrap';
import 'blueimp-file-upload/js/jquery.fileupload';
import {app_flashes} from "../../app/js/utils/app.flashes";
import {document} from "./utils/document";
import {edit_result} from "./utils/edit.result";

global.$ = global.jQuery = $;
global.Noty = require("noty");
$(function () {
    app_flashes();
    document();
    edit_result();
});